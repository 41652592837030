import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import { selectProfil } from '../users/+profil/profil.selectors';

import { selectCombinedFactories } from '../factories/+store/factory.selectors';
import { FactoryActions } from '../factories/+store/factory.actions';
import { selectCombinedContracts } from '../contracts/+store/contract.selectors';
import { ContractActions } from '../contracts/+store/contract.actions';
import { selectCombinedStations } from '../stations/+store/station.selectors';
import { StationActions } from '../stations/+store/station.actions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedFactories$ = this.store.select(selectCombinedFactories);
  combinedContracts$ = this.store.select(selectCombinedContracts);
  combinedStations$ = this.store.select(selectCombinedStations);

  constructor(
    private store: Store,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    // this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(FactoryActions.loadFactories());
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(ContractActions.loadContracts());
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(StationActions.loadStations());
      }
    });

    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home' };

    this.breadcrumbService.updateBreadcrumb([breadcrumb1]);
  }
}
