<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<!-- <div class="clr-row">
  <div class="clr-col-xl-4 clr-col-lg-12 clr-col-12">
    <app-factories-list-graph
      [factories$]="factoriesList$"
      [loading$]="loadingFactories$"
      [error$]="errorFactories$"
      [extern]="true"
    ></app-factories-list-graph>
  </div>
  <div class="clr-col-xl-4 clr-col-lg-12 clr-col-12">
    <app-contracts-list-graph
      [contracts$]="contractsList$"
      [loading$]="loadingContracts$"
      [error$]="errorContracts$"
      [extern]="true"
    ></app-contracts-list-graph>
  </div>
  <div class="clr-col-xl-4 clr-col-lg-12 clr-col-12">
    <app-stations-list-graph
      [stations$]="stationsList$"
      [loading$]="loadingStations$"
      [error$]="errorStations$"
      [extern]="true"
    ></app-stations-list-graph>
  </div>
</div> -->

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedStations$ | async as combinedStations">
      <app-stations-list-map
        [extern]="true"
        [stations]="combinedStations.stations"
        [loading]="combinedStations.loading"
        [error]="combinedStations.error"
      ></app-stations-list-map>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedStations$ | async as combinedStations">
      <app-stations-list-grid-card
        [extern]="true"
        [stations]="combinedStations.stations"
        [loading]="combinedStations.loading"
        [error]="combinedStations.error"
      ></app-stations-list-grid-card>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedContracts$ | async as combinedContracts">
      <app-contracts-list-grid-card
        [extern]="true"
        [contracts]="combinedContracts.contracts"
        [loading]="combinedContracts.loading"
        [error]="combinedContracts.error"
      ></app-contracts-list-grid-card>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedFactories$ | async as combinedFactories">
      <app-factories-list-grid-card
        [extern]="true"
        [factories]="combinedFactories.factories"
        [loading]="combinedFactories.loading"
        [error]="combinedFactories.error"
      ></app-factories-list-grid-card>
    </div>
  </div>
</div>
